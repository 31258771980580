import { Container, Divider, Grid } from '@mui/material';
import React from 'react';
import ButtonControl from './ButtonControl';
import CardControl from './CardControl';
import TransactionControl from './TransactionControl';

const ControlPanel = ({transactions, handleBuy, handleSell, handleTogglePause, stockAmount, moneyAmount, stockPrice, previousStockPrice}) => {
    
    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={8}>
                        {/* blank item */}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <CardControl smallInfo={"Current stock price"} bigInfo={stockPrice.toFixed(2)} textColor={previousStockPrice <= stockPrice ? "green" : "red"}/>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardControl smallInfo={"Current stock amount"} bigInfo={stockAmount}/>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardControl smallInfo={"Current wallet value"} bigInfo={moneyAmount}/>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <ButtonControl buttonText='SELL' color='error' buttonFunction={handleSell}/>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <ButtonControl buttonText='BUY' color='success' buttonFunction={handleBuy}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ButtonControl buttonText='TOGGLE PAUSE' color='warning' buttonFunction={handleTogglePause}/>
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ my: 2 }}>
                <Divider/>
            </Container>

            <Container>
                {transactions.map((transaction, key) => {
                    return (
                        <TransactionControl transaction={transaction} key={key} />
                    );
                })}
            </Container>
        </>
    );
};

export default ControlPanel;
