import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

const TransactionControl = ({transaction}) => {
    
    return (
      <Card sx={{ my: 1 }}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: '16px !important' }}>
          <Typography>
            {transaction.direction}
          </Typography>
          <Typography variant='body'>
            {transaction.price.toFixed(4)}
          </Typography>
          <Typography variant='body'>
            {transaction.stockCount}
          </Typography>
          <Typography variant='body'>
            {transaction.transactionPrice.toFixed(4)}
          </Typography>
        </CardContent>
      </Card>
    );
};

export default TransactionControl;
