import { Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

const CardControl = ({smallInfo, bigInfo, textColor = 'inherit'}) => {
    
    return (
      <Card>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: '16px !important' }}>
          <Typography>
            {smallInfo}
          </Typography>
          <Typography variant='h5' sx={{ color: textColor }}>
            {bigInfo}
          </Typography>
        </CardContent>
      </Card>
    );
};

export default CardControl;
