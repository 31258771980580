import seedrandom from 'seedrandom';

  // Permutation table. This needs to be the same for all instances to maintain consistency.
  // Generate a permutation table based on a seed
  export function drawEvent() {
    const eventChance = drawNumber();

    if (eventChance > 6) {
      //event happened

      const eventType = drawNumber();

      if (eventType > 5) {
        // good event
        console.log('good event occured!');
        return 1;
      } else {
        // bad event
        return 2;
      }
    }
  }

  function drawNumber() {
    return Math.floor(Math.random() * 10) + 1;
  }