import './App.css';

import React, { useState, useEffect } from 'react';
import StockLineChart from './components/Charts/StockLineChart';
import { Button, Container, Typography } from '@mui/material';

import { perlinNoise } from './PerlinNoise/PerlinNoise';
import { drawEvent } from './PerlinNoise/Events';
import ControlPanel from './components/Controls/ControlPanel';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#111827',
      paper: '#0f172a',
    }
  },
});

function App() {
  const [data, setData] = useState([]);
  const [pause, setPause] = useState(true);

  const [transactions, setTransactions] = useState([]);

  const [currentValue, setCurrentValue] = useState(0);
  const [time, setTime] = useState(0);

  const [stockPrice, setStockPrice] = useState(10);
  const [previousStockPrice, setPreviousStockPrice] = useState(10);

  const [wallet, setWallet] = useState(10);
  const [stockWallet, setStockWallet] = useState(0);
  const [havingStock, setHavingStock] = useState(false);

  const handleBuy = () => {
    // console.log(wallet * currentValue);
    if (havingStock === false) {
      var stockCount = Math.floor(wallet / stockPrice);
      setWallet(0);
      setStockWallet(stockCount);
      setHavingStock(true);

      const newTransaction = {
        direction: "buy",
        price: stockPrice,
        stockCount: stockCount,
        transactionPrice : stockPrice * stockCount
      }

      const newTransactions = [...transactions, newTransaction];
      setTransactions(newTransactions);
    }
  }

  const handleSell = () => {
    if (havingStock === true) {
      var coins = Math.floor(stockWallet * stockPrice);
      setWallet(coins);
      setStockWallet(0);
      setHavingStock(false);

      const newTransaction = {
        direction: "sell",
        price: stockPrice,
        stockCount: stockWallet,
        transactionPrice : stockPrice * stockWallet
      }

      const newTransactions = [...transactions, newTransaction].slice(-20).reverse();
      setTransactions(newTransactions);
    }
  }

  const handleTest = () => {
    console.log("test");
  }

  const handleTogglePause = () => {
    setPause(!pause);
  }

  useEffect(() => {
    if (!pause) {
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime + 0.1);
  
        const newChangeValue = perlinNoise(time); // Assuming this gives the percentage change
        // Event generator
        const modifier = drawEvent();
        if (modifier === 1) {
          console.log(`normal val: ${newChangeValue} // modified val: ${newChangeValue + 3}`);
          setCurrentValue(newChangeValue + 2);
        } else if (modifier === 2) {
          setCurrentValue(newChangeValue - 1.5);
        } else {
          setCurrentValue(newChangeValue);
        }

        setPreviousStockPrice(stockPrice);
        // Correcting the calculation here: Increase stock price on positive change, decrease on negative
        const newStockValue = currentValue >= 0 
          ? stockPrice * (1 + currentValue / 10) 
          : stockPrice * (1 - Math.abs(currentValue) / 10);
  
        //console.log(`new change: ${newChangeValue}, stock price: ${stockPrice}, new stock value: ${newStockValue}`);
        // console.log(`wallet value: ${stockWallet * stockPrice}`);
  
        // Ensure stockPrice is updated correctly
        setStockPrice(newStockValue); // Assuming you have a setStockPrice function to update state
  
        // Add the new perlinValue to the data, maintaining the last 20 entries
        const perlinValue = { step: parseInt(time * 10), value: newStockValue };
        setData((currentData) => [...currentData, perlinValue].slice(-20));
  
      }, 1000); // Update every 3000 milliseconds (3 seconds)
  
      return () => clearInterval(interval);
    }
  }, [time, pause, stockPrice]); // Include stockPrice in the dependency array if its updates should trigger this effect

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div>
        <Container>
          <h1>Stock Market Simulator</h1>
        </Container>
        <div >
          <Container sx={{ height: '400px' }}>
            <StockLineChart data={data}/>
          </Container>
        </div>

        <ControlPanel transactions={transactions} handleSell={handleSell} handleBuy={handleBuy} handleTogglePause={handleTogglePause} stockAmount={stockWallet} moneyAmount={wallet} stockPrice={stockPrice} previousStockPrice={previousStockPrice} />        
      </div>
    </ThemeProvider>
  );
}

export default App;
