import seedrandom from 'seedrandom';

  // Permutation table. This needs to be the same for all instances to maintain consistency.
  // Generate a permutation table based on a seed
  function generatePerm(seed) {
    const perm = new Array(256).fill(0).map((_, i) => i);
    let rng = seedrandom(seed);
  
    // Shuffle the array with the Fisher-Yates algorithm
    for (let i = 255; i > 0; i--) {
      const j = Math.floor(rng() * (i + 1));
      [perm[i], perm[j]] = [perm[j], perm[i]];
    }
  
    // Duplicate the permutation array
    return perm.concat(perm);
  }
  
  export function perlinNoise(x) {
    // Here we use a fixed seed for consistency; you could use a random one instead
    const perm = generatePerm('your_seed_here');
    
    // Calculate the "unit cube" that the point asked will be located in
    const xi = Math.floor(x) & 255; // Calculate the remainder of x / 256
    const xf = x - Math.floor(x);

    // Fade curves for xf
    const u = fade(xf);

    // Hash coordinates of the cube corners
    const n0 = perm[xi];
    const n1 = perm[xi + 1];

    // Add blended results from the corners
    const noise = lerp(u, grad(n0, xf), grad(n1, xf - 1));
    return noise;
  }
  
  function fade(t) {
    // Fade function as defined by Ken Perlin
    // This eases coordinate values so that they will ease towards integral values
    // This ends up smoothing the final output
    return t * t * t * (t * (t * 6 - 15) + 10);
  }
  
  function lerp(t, a, b) {
    // Linear interpolate between a and b
    return a + t * (b - a);
  }
  
  function grad(hash, x) {
    // Calculate a "gradient" from a hash value
    const h = hash & 15;
    let grad = 1 + (h & 7); // Gradient value is one of 1, 2, ..., 8
    if ((h & 8) !== 0) grad = -grad; // and a random sign for the gradient
    return (grad * x); // Multiply the gradient with the distance
  }
  
  // Example usage:
  // const point = 1;
  // const noiseValue = perlinNoise(point);
  // console.log(`Noise value at point ${point}: ${noiseValue}`);
  