import { Button } from '@mui/material';
import React from 'react';

const ButtonControl = ({buttonText = 'button text', color = 'primary', buttonFunction}) => {
    
    return (
      <Button variant='contained' color={color} onClick={buttonFunction} fullWidth>
        {buttonText}
      </Button>
    );
};

export default ButtonControl;
