import React from 'react';
import { LineChart, CartesianGrid, Legend, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const exampleData = [
  {
    "step": "Page A",
    "value": 2400,
  },
  {
    "step": "Page B",
    "value": 1398,
  },
  {
    "step": "Page C",
    "value": 9800,
  },
  {
    "step": "Page D",
    "value": 3908,
  },
  {
    "step": "Page E",
    "value": 4800,
  }
]

const StockLineChart = ({data}) => {
    
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={730} height={250} data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="step" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    );
};

export default StockLineChart;
